import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import WorkRequest from "../../../api/WorkRequest/WorkRequest";
import WorkRequestBusinessUnit from "../../../api/WorkRequest/WorkRequestBusinessUnit";
import WorkRequestFacility from "../../../api/WorkRequest/WorkRequestFacility";
import WorkRequestFileAttachment from "../../../api/WorkRequest/WorkRequestFileAttachment";
import WorkRequestPriority from "../../../api/WorkRequest/WorkRequestPriority";
import WorkRequestStatus from "../../../api/WorkRequest/WorkRequestStatus";
import WorkRequestType from "../../../api/WorkRequest/WorkRequestType";

import TrashIcon from "@mui/icons-material/Delete";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../components/UXDataTable";
import { GlobalButton } from "../../styles";

import { Box } from "@mui/system";
import { usePDF } from "@react-pdf/renderer";
import { useFormik } from "formik";
import Location from "../../../api/Admin/Location";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import ShippingCarrier from "../../../api/WorkRequest/ShippingCarrier";
import BillingInfoField from "../../../components/BillingInfoField";
import EmailAddressTextField from "../../../components/EmailAddressField";
import FileDragAndDrop from "../../../components/FileDragAndDrop";
import MethodSelection from "../../../components/MethodSelection";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import UserContext from "../../../context/UserContext";
import {
  Roles,
  convertCelsiusToFahrenheit,
  convertFahrenheitToCelsius,
  getMessage,
  hasRole,
  isEmailValid,
  DatePickerKeyDownEvent,
  formatCreatedAndModifiedDateInfo,
  convertFlashPoint,
  downloadFile
} from "../../../global";

import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import WorkRequestSampleInfoTable from "../NewWorkRequests/components/WorkRequestSampleInfoTable";
import {
  FILE_ATTACHMENT_TABLE_COLUMNS,
  StyledAutocomplete,
  StyledShortTextField,
  StyledTextField,
  SubTitle,
  Title,
  defaultContainer,
  defaultErrorChecksWorkRequest,
  defaultShippingItem,
  specialAnalysisErrors,
  defaultSpecialAnalysisObject,
  defaultShippingRequest,
  DescriptionLink,
} from "../NewWorkRequests/constants.ts";
import SpecialAnalysisForm from "../SpecialAnalysisForm";
import useContainerStatus from "../hooks/useContainerStatus";
import ShippingForm from "./ShippingForm";
import ManageContainer from "./components/ManageContainer";
import PrintWorkRequest from "./components/PrintWorkRequest";
import WorkRequestNonChemicalTable from "./components/WorkRequestNonChemicalTable";
import { CONTAINERING_METHOD_ENUM } from "../WorkRequestTypes/ContainerRegistrationWithTest";
import PrintInformation from "../../../components/PrintInformation";
import PrintLabel from "../../../api/LIMS/PrintLabel";

const ViewWorkRequestForm = ({
  myWorkRequest,
  setMyWorkRequest,
  files,
  setFiles,
  billingList,
  fromTab,
  dotHazardClass,
  dotHazardClassLoading,
  initialStatus,
}) => {
  const currentUser = useContext(UserContext);
  const userRoles = currentUser?.idTokenClaims.roles;
  const [testJson, setTestJson] = useState({
    containeringMethod: CONTAINERING_METHOD_ENUM.MIDAS,
    methods: [],
  });
  const [newComment, setNewComment] = useState("");
  const [errorChecks, setErrorChecks] = useState(defaultErrorChecksWorkRequest);

  const [modalCancelRequestIsOpen, setModalCancelRequestIsOpen] =
    useState(false);
  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const [modalMessagesText, setModalMessagesText] = useState("");
  const [modalMessagesTitle, setModalMessagesTitle] = useState("");
  const [modalMessagesButtonText, setModalMessagesButtonText] = useState("Ok");
  const shouldRefresh = useRef(false);
  const { containerStatus } = useContainerStatus();
  const [expanded, setExpanded] = useState("panel1");

  const [requestTypes, setRequestTypes] = useState([]);
  const [requestTypesLoading, setRequestTypesLoading] = useState(true);

  const [requestFacilities, setRequestFacilities] = useState([]);
  const [requestFacilitiesLoading, setRequestFacilitiesLoading] =
    useState(true);

  const [requestBusinessUnits, setRequestBusinessUnits] = useState([]);
  const [requestBusinessUnitsLoading, setRequestBusinessUnitsLoading] =
    useState(true);

  const [unitOfMeasure, setUnitOfMeasure] = useState([]);
  const [unitOfMeasureWUnit, setUnitOfMeasureWUnit] = useState([]);
  const [unitOfMeasureLoading, setUnitOfMeasureLoading] = useState(true);

  const [locations, setLocations] = useState([]);
  const [locationsLoading, setLocationsLoading] = useState(true);

  const [requestStatus, setRequestStatus] = useState([]);
  const [requestStatusLoading, setRequestStatusLoading] = useState(true);

  const [priorities, setPriorities] = useState([]);
  const [prioritiesLoading, setPrioritiesLoading] = useState(true);

  const [assignToHasErrors, setAssignToHasErrors] = useState(false);
  const [containersHasError, setContainersHasError] = useState(false);

  const [containerRecommendation, setContainerRecommendation] = useState([]);
  const [containerRecommendationErrorCheck, setContainerRecommendationErrorCheck] = useState(false);

  const [shippingRequest, setShippingRequest] = useState(() => {
    let newShippingRequest = structuredClone(myWorkRequest.shippingRequest);
    if (newShippingRequest) {
      newShippingRequest.shippingItems =
        myWorkRequest.shippingRequest.shippingItems.map((x, index) => {
          const flashPointC = x.flashPointInC ? convertFlashPoint(convertCelsiusToFahrenheit, x.flashPointInC) ?? null : null;
          const sample = myWorkRequest.workRequestSamples[index];
          const container = x.thisContainer ?? sample?.existingContainer ?? sample?.newContainer;

          return {
            ...x,
            containerID: x.containerID ?? sample?.existingContainerID ?? sample?.newContainerID,
            flashPointInC: flashPointC,
            sampleName: container?.sample?.sampleName ?? sample?.sampleName,
            thisContainer: container,
          }
        });
    } else {
      newShippingRequest = defaultShippingRequest;
    }
    return newShippingRequest;
  });

  const [shippingCarriers, setShippingCarriers] = useState([]);
  const [shippingCarriersLoading, setShippingCarriersLoading] = useState(false);

  const [printLabelInfo, setPrintLabelInfo] = useState(null);
  const [printInfoOpen, setPrintInfoOpen] = useState(false);

  const categoriesToShowPrint = ['Container Registration', 'Container Registration w/ Tests', 'Miscellaneous Request']

  const [instance, update] = usePDF({
    document: (
      <PrintWorkRequest
        workRequest={myWorkRequest}
        files={files}
        methods={testJson.methods}
        billingList={billingList}
        dotHazardClass={dotHazardClass ?? []}
      />
    ),
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(update, [myWorkRequest, files, testJson.methods]);

  let history = useHistory();
  const assignedRef = useRef();

  useEffect(() => {
    if (!assignedRef.current)
      assignedRef.current = myWorkRequest.assignedToEmail;
  }, [myWorkRequest]);

  const specialAnalysisErrorCheck = specialAnalysisErrors;
  const mustAssignPersonToWorkRequest = false;
  // myWorkRequest?.statusName !== "Cancelled";

  const isCancelledOrCompleted = initialStatus === "Cancelled" || initialStatus === "Completed";
  const isDeveloperRole = hasRole(Roles.Developer, userRoles) && !isCancelledOrCompleted;
  // currentUser?.username === myWorkRequest?.requestedByEmail &&
  // initialStatus === "Submitted";
  const isOwner = currentUser?.username === myWorkRequest?.requestedByEmail || currentUser?.username === myWorkRequest?.createdByEmail;
  const ownerCanEdit = isOwner && initialStatus === "Submitted";
  const statusOwnerCanSee = ["OnHold", "Submitted", "Cancelled"]

  const isTechnicianRole =
    (hasRole(Roles.WorkRequestTechnician, userRoles) &&
      currentUser.username === assignedRef.current) ||
    isDeveloperRole;

  const isRoleBusiness = hasRole(Roles.WorkRequestTechnician, userRoles) || hasRole(Roles.WorkRequestShippingCoordinator, userRoles) || isDeveloperRole;

  const isFacilityAssignorRole =
    hasRole(Roles.WorkRequestFacilityAssigner, userRoles) || isDeveloperRole;

  const isAssignerRole =
    hasRole(Roles.WorkRequestAssigner, userRoles) || isDeveloperRole;

  const isShippingRole =
    hasRole(Roles.WorkRequestShippingCoordinator, userRoles) || isDeveloperRole;

  const formik = useFormik({
    initialValues: myWorkRequest.specialRequest ?? defaultSpecialAnalysisObject,

    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      setMyWorkRequest((prevState) => ({
        ...prevState,
        specialRequest: values,
      }));
      formik.resetForm();
    },
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const usingOnSiteTransport = myWorkRequest?.typeName === "On-site Transport";
  const usingContainerRegistration =
    myWorkRequest?.typeName === "Container Registration";
  const usingContainerRegistrationTests =
    myWorkRequest?.typeName === "Container Registration w/ Tests";

  const usingShipping = myWorkRequest?.typeName === "Shipping";
  const usingSpecialAnalysis = myWorkRequest?.typeName === "Special Analysis";

  const isProcessWorkRequest = fromTab === "process";
  const showManageContainers = isProcessWorkRequest && 
    (usingContainerRegistration || usingContainerRegistrationTests || usingShipping);
  const isSubmitContainerRegistrationTests = !isProcessWorkRequest && usingContainerRegistrationTests;  

  const containerErrorMessage =`Container(s) has error. 
  If you encounter any errors with a created container, please
  update the information in the 'Sample Registration' module or in
  the 'Manage Container Data' section of the 'Inventory' module.`;

  useEffect(() => {
    if (
      !testJson.methods?.length &&
      myWorkRequest.testSubmissionJSON &&
      myWorkRequest.testSubmissionJSON !== "[]" &&
      myWorkRequest.testSubmissionJSON !== ""
    ) {
      const parsedMethods = JSON.parse(myWorkRequest.testSubmissionJSON);
      if (parsedMethods) {
        setTestJson(parsedMethods);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myWorkRequest]);

  useMemo(() => {
    if ((usingContainerRegistration || usingShipping) && containerStatus)
      setMyWorkRequest((prev) => ({
        ...prev,
        workRequestSamples: [
          ...prev.workRequestSamples.map((sample) => {
            let parentContainer = sample.existingContainer ?? "";
            let currentContainer = sample.newContainer ?? defaultContainer;
            return {
              ...sample,
              newContainer:
                currentContainer.containerNumber > 0
                  ? currentContainer
                  : {
                    ...currentContainer,
                    containerStatusName: "Confirmed",
                    containerStatus: containerStatus.find(
                      (x) => x.name === "Confirmed"
                    ),
                    parentContainer: sample.existingContainer ?? null,
                    locationName: sample.requestedLocationName,
                    subLocation: sample.deliveryLocationSublocation,
                    ownerEmail: prev.requestedByEmail,
                    containerSizeUoM: sample.requestedAmountUoM,
                    containerTypeName:
                      parentContainer.containerTypeName ?? "",
                    containerType: parentContainer.containerType ?? null,
                    currentAmount: sample.requestedAmount,
                    size: parentContainer.currentAmount,
                  },
            };
          }),
        ],
      }));
  }, [usingContainerRegistration, usingShipping, setMyWorkRequest, containerStatus]);

  function updateData_ShippingItem(property, value, item) {
    let itemsCopy = structuredClone(shippingRequest.shippingItems);
    itemsCopy.find((x) => x.id === item.id)[property] = value;

    setShippingRequest({
      ...shippingRequest,
      shippingItems: itemsCopy,
    });
  }

  function handleFiles(newFiles) {
    const listFiles = newFiles.map((item) => {
      return {
        file: item,
        sendWithShipment: false,
        isSDS: false,
      };
    });
    setFiles(files.concat(listFiles));
  }

  function deleteFile(file) {
    const newFiles = files.filter((f) => f !== file);

    setFiles(newFiles);
  }

  function handleChangeFile(property, index) {
    const listFiles = files.map((file, idx) => {
      if (index === idx && file.file?.id) {
        return {
          ...file,
          file: {
            ...file.file,
            [property]: !file.file[property],
          },
        };
      } else if (index === idx) {
        return {
          ...file,
          [property]: !file[property],
        };
      }
      return file;
    });

    setFiles([...listFiles]);
  }

  function AutoCompleteChangeAndValidate(property, value) {
    const copyMyWorkRequest = JSON.parse(JSON.stringify(myWorkRequest));

    copyMyWorkRequest[property] = value;
    if (property === "workRequestTypeName") {
      copyMyWorkRequest.workRequestFacilityName = null;
    }

    setMyWorkRequest(copyMyWorkRequest);
  }

  useEffect(() => {
    let cancelPromise = false;
    WorkRequestType.getAll().then((res) => {
      if (cancelPromise) return;
      setRequestTypesLoading(false);
      setRequestTypes(
        res
          .filter((result) => result.isActive === true)
          .sort((a, b) => a.typeName.localeCompare(b.typeName))
      );
    });

    WorkRequestFacility.getAll().then((res) => {
      if (cancelPromise) return;
      setRequestFacilitiesLoading(false);
      setRequestFacilities(
        res
          .filter((result) => result.isActive === true)
          .sort((a, b) => a.facilityName.localeCompare(b.facilityName))
      );
    });

    WorkRequestBusinessUnit.getAll().then((res) => {
      if (cancelPromise) return;
      setRequestBusinessUnitsLoading(false);
      setRequestBusinessUnits(
        res
          .filter((result) => result.isActive === true)
          .sort((a, b) => a.businessUnitName.localeCompare(b.businessUnitName))
      );
    });

    UnitOfMeasure.getAllActive().then((res) => {
      if (cancelPromise) return;
      setUnitOfMeasureLoading(false);
      setUnitOfMeasureWUnit(
        res
          .filter(
            (result) =>
              result.type === "weight" ||
              result.type === "volume" ||
              result.type === "unit"
          )
          .sort((a, b) => a.uoMName.localeCompare(b.uoMName))
      );
      setUnitOfMeasure(
        res
          .filter(
            (result) =>
              (result.type === "weight" || result.type === "volume") &&
              result.metricStandardConversion !== null
          )
          .sort((a, b) => a.uoMName.localeCompare(b.uoMName))
      );
    });

    Location.getAll().then((res) => {
      if (cancelPromise) return;
      setLocationsLoading(false);
      setLocations(
        res
          .filter((result) => result.isActive === true)
          .sort((a, b) => a.locationName.localeCompare(b.locationName))
      );
    });

    WorkRequestStatus.getAll().then((res) => {
      if (cancelPromise) return;
      setRequestStatusLoading(false);
      setRequestStatus(
        res
          .filter((result) => result.isActive === true)
          .sort((a, b) => a.statusName.localeCompare(b.statusName))
      );
    });

    WorkRequestPriority.getAll().then((res) => {
      if (cancelPromise) return;
      setPrioritiesLoading(false);
      setPriorities(
        res
          .filter((result) => result.isActive === true)
          .sort((a, b) => a.priorityName.localeCompare(b.priorityName))
      );
    });

    if (usingShipping) {
      setShippingCarriersLoading(true);
      ShippingCarrier.getAll().then((res) => {
        if (cancelPromise) return;
        setShippingCarriersLoading(false);
        setShippingCarriers(
          res
            .filter((result) => result.isActive === true)
            .sort((a, b) => a.carrierName.localeCompare(b.carrierName))
        );
      });
    }
    return () => {
      cancelPromise = true;
    };
  }, [usingShipping]);

  const mountComments = (comments) => {
    let comment = "";
    comments.forEach((item) => {
      comment += item + "\n";
    });
    return comment;
  };

  function addComment() {
    let timeStamp = new Date().toLocaleString();

    let commentWithDate = `${currentUser.username} (${timeStamp}): \t ${newComment}`;
    WorkRequest.updateComment(myWorkRequest.id, commentWithDate).then((res) => {
      if (
        res &&
        res.message === "Success" &&
        res.result &&
        res.result.comments
      ) {
        setMyWorkRequest({
          ...myWorkRequest,
          comments: res.result.comments,
        });
        setNewComment("");
      } else {
        setModalMessagesOpen(true);
        setModalMessagesText(`Something went wrong, try again later. ${res?.message ? res?.message : ""}`);
        setModalMessagesButtonText("Ok");
        setModalMessagesTitle("Work Request Comment");
      }
    });
  }

  function printRequest() {
    if (!instance.loading && !instance.error && instance.url) {
      const link = document.createElement("a");
      link.href = instance.url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const jadeRequired =
    myWorkRequest?.typeName === "Container Registration w/ Tests" ||
    myWorkRequest?.typeName === "Shipping" ||
    myWorkRequest?.typeName === "Special Analysis";

  const areFieldsInvalidFunc = () => {
    let fieldsInvalidList = [];
    let hasError = false;
    let typeName = null;
    let facilityName = null;
    let businessUnitName = null;
    let requestedByEmail = null;
    let statusName = null;
    let chargeCode = null;

    if (!myWorkRequest.typeName) {
      fieldsInvalidList.push("Category");
      typeName = getMessage("REQUIRED_FIELD");
    }
    if (!myWorkRequest.assignedToEmail && mustAssignPersonToWorkRequest) {
      setAssignToHasErrors(true);
      hasError = true;
      fieldsInvalidList.push("Assigned To");
    }
    if (!myWorkRequest.facilityName) {
      fieldsInvalidList.push("Facility");
      facilityName = getMessage("REQUIRED_FIELD");
    }
    if (!myWorkRequest.businessUnitName) {
      fieldsInvalidList.push("Business Unit");
      businessUnitName = getMessage("REQUIRED_FIELD");
    }
    if (!myWorkRequest.requestedByEmail) {
      fieldsInvalidList.push("Requester Name");
      requestedByEmail = getMessage("REQUIRED_FIELD");
    } else if (!isEmailValid(myWorkRequest.requestedByEmail)) {
      fieldsInvalidList.push("Requester Name");
      requestedByEmail = "Invalid E-mail";
    }

    if (!myWorkRequest.statusName) {
      fieldsInvalidList.push("Status");
      statusName = getMessage("REQUIRED_FIELD");
    }

    if (
      myWorkRequest.priorityName === "Rush" &&
      !myWorkRequest.priorityReason
    ) {
      fieldsInvalidList.push("Priority Reason");
      hasError = true;
    }

    if (!myWorkRequest.chargeCode && jadeRequired) {
      fieldsInvalidList.push("JADE Number");
      chargeCode = getMessage("REQUIRED_FIELD");
    }

    setErrorChecks({
      ...errorChecks,
      typeName: typeName,
      facilityName: facilityName,
      businessUnitName: businessUnitName,
      requestedByEmail: requestedByEmail,
      statusName: statusName,
      chargeCode: chargeCode,
    });

    const invalidAssignedPersonToWorkRequest =
      assignToHasErrors && mustAssignPersonToWorkRequest;

    if (containersHasError) {
      fieldsInvalidList.push(containerErrorMessage);
    }

    return {
      hasError:
        typeName !== null ||
        facilityName !== null ||
        businessUnitName !== null ||
        requestedByEmail !== null ||
        statusName !== null ||
        chargeCode !== null ||
        invalidAssignedPersonToWorkRequest ||
        hasError ||
        containersHasError,
      fields: fieldsInvalidList,
    };
  };

  const prepareData = (workRequest, isCopy = false) => {
    const workRequestSamples = (isSubmitContainerRegistrationTests ? 
      containerRecommendation : workRequest.workRequestSamples
    ).filter((wrs) => wrs.sample);
    return {
      ...workRequest,
      businessUnitName: workRequest.businessUnitName,
      businessUnit: null,
      facilityName: workRequest.facilityName,
      workRequestFacilityName: null,
      typeName: workRequest.typeName,
      workRequestTypeName: null,
      testSubmissionJSON: JSON.stringify(testJson),
      workRequestSamples: workRequestSamples.map((sample) => {
        return {
          ...sample,
          sample: isCopy ? sample.sample : null,
          sampleName: sample.sample?.sampleName,
          unitOfMeasure: null,
          requestedAmountUoM: sample.requestedAmountUoM,
          requestedAmount: sample.requestedAmount ?? 0,
          location: null,
          requestedLocationName: sample.requestedLocationName,
          container: null,
          existingContainerID: sample.existingContainerID,
          existingContainer: null,
          newContainer: (!isSubmitContainerRegistrationTests && sample.newContainer)
            ? {
              ...sample.newContainer,
              uom: null,
              location: null,
              containerStatus: null,
              containerType: null,
              parentID: sample.newContainer?.parentContainer?.id ?? null,
              parentContainer: null,
            }
            : null,
        };
      }),
      shippingRequest: usingShipping
        ? {
          ...shippingRequest,
          printBlindCode: shippingRequest.shippingItems.some(s => s.blindCodeDescription && s.blindCodeDescription !== ''),
          shippingItems: shippingRequest.shippingItems.map((item) => {
            return {
              id: item.id,
              shippingRequestID: item.shippingRequestID,
              thisRequest: item.thisRequest,
              containerID: item.containerID,
              thisContainer: null,
              description: item.description,
              dotHazardClass: item.dotHazardClass,
              exportValue: item.exportValue,
              flashPointInC: convertFlashPoint(convertFahrenheitToCelsius, item.flashPointInC),
              quantity: item.quantity,
              quantityUoMName: item.quantityUoMName,
              quantityUoM: item.quantityUoM,
              comment: item.comment,
              blindCodeDescription: item.blindCodeDescription,
              sampleName: isCopy ? item.sampleName : null,
            };
          }),
          carrier: null,
          shippingAddressID:
            shippingRequest?.shippingAddressID === 0
              ? null
              : shippingRequest?.shippingAddressID,
        }
        : null,
    };
  };

  function sendFiles(id) {
    const filesToCreate = files.filter((f) => !f.file?.id);
    const filesToUpdate = files.filter((f) => f.file?.id);
    const lastIndexToCreate = filesToCreate.length - 1;
    const lastIndexsToUpdate = filesToUpdate.length - 1;
    let finishUpdate = true;
    let finishCreate = true;
    let error = false;
    filesToCreate.forEach((file, index) => {
      finishCreate = false;
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("workRequestID", id);
      formData.append("sendWithShipment", file.sendWithShipment);
      formData.append("isSDS", file.isSDS);

      WorkRequestFileAttachment.postAttachs(formData)
        .then((res) => {
          if (lastIndexToCreate === index && finishUpdate) {
            const message = error
              ? "Work Request Updated, but Something went wrong with the attatchments, please try after sometime"
              : "Work Request Updated with success";
            finallyUpdate(message, true);
            finishCreate = true;
          }
        })
        .catch((resp) => {
          error = true;
          if (lastIndexToCreate === index && finishUpdate) {
            const message = error
              ? "Work Request Updated, but Something went wrong with the attatchments, please try after sometime"
              : "Work Request Updated with success";
            finallyUpdate(message, true);
            finishCreate = true;
          }
        });
    });

    filesToUpdate.forEach((file, index) => {
      WorkRequestFileAttachment.updateWorkRequest(file.file)
        .then(() => {
          if (lastIndexsToUpdate === index && finishCreate) {
            const message = error
              ? "Work Request Updated, but Something went wrong with the attatchments, please try after sometime"
              : "Work Request Updated with success";
            finallyUpdate(message, true);
            finishUpdate = true;
          }
        })
        .catch((resp) => {
          error = true;
          if (lastIndexsToUpdate === index && finishCreate) {
            const message = error
              ? "Work Request Updated, but Something went wrong with the attatchments, please try after sometime"
              : "Work Request Updated with success";
            finallyUpdate(message, true);
            finishUpdate = true;
          }
        });
    });
  }

  function finallyUpdate(message, redirect) {
    setModalMessagesOpen(true);
    setModalMessagesText(message);
    setModalMessagesButtonText("Ok");
    setModalMessagesTitle("Work Request Update");

    shouldRefresh.current = redirect;
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText("");
    setModalMessagesButtonText("");
    setModalMessagesTitle("");
    if (shouldRefresh.current) history.push("/processWorkRequests");
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
    setModalMessagesButtonText("Ok");
  }

  function submit() {
    const areFieldsInvalid = areFieldsInvalidFunc();

    if (areFieldsInvalid.hasError) {
      const fields = areFieldsInvalid.fields.join("\n");
      setModalMessagesOpen(true);
      setModalMessagesText("There are required fields missing:\n\n" + fields);
      setModalMessagesButtonText("Ok");
      setModalMessagesTitle("Missing information!");
      return;
    }
    const data = prepareData(myWorkRequest);
    WorkRequest.updateWorkRequest(data)
      .then((res) => {
        if (res?.message !== 'Success')
        {
          finallyUpdate(`Something went wrong, try again later. ${res?.message ? res?.message : ""}`, false);
        } else {
          if (files.length > 0) {
            sendFiles(res.result.id);
          } else {
            finallyUpdate("Work Request Updated with success", true);
          }
        }
      })
      .catch((err) => {
        finallyUpdate(`Something went wrong, try again later. ${err?.message ? err?.message : ""}`, false);
      });
  }

  let nonChemicalShipping = useMemo(
    () =>
      shippingRequest?.shippingItems.filter(
        (i) => i.sampleName === null && !!i.description
      ),
    [shippingRequest?.shippingItems]
  );

  const canChange = myWorkRequest.statusName && myWorkRequest.statusName === "Submitted";

  useEffect(() => {
    if (!usingShipping) return;
    let chemicals = myWorkRequest.workRequestSamples.filter(
      (i) => i.sample !== null
    );

    if (!chemicals.length) return;
    let chemicalShipping = [];
    chemicalShipping = chemicals.map((item) => {
      const containerID = item.existingContainerID ?? item.newContainerID;
      const currentShippingItemIfExist =
        shippingRequest?.shippingItems?.find(
          (x) => x.sampleName === item.sample.sampleName && x.containerID === containerID
        ) ?? structuredClone(defaultShippingItem);

      const flashPointC = currentShippingItemIfExist.flashPointInC ? currentShippingItemIfExist.flashPointInC :
        item.sample?.substance?.flashPointC ? convertFlashPoint(convertCelsiusToFahrenheit, item.sample?.substance?.flashPointC) : null;
      return {
        ...currentShippingItemIfExist,
        sampleName: item.sample.sampleName,
        psimsid: item.sample.psimsid,
        containerID: containerID,
        description: item.sample.description,
        flashPointInC: flashPointC,
        thisContainer: currentShippingItemIfExist.thisContainer ?? item.sample.containers.find(f => f.id === containerID)
      };
    });
    setShippingRequest((prev) => ({
      ...prev,
      shippingItems: [
        ...chemicalShipping,
        ...prev.shippingItems.filter((x) => !x.sampleName),
      ],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myWorkRequest.workRequestSamples]);

  const cancelRequest = () => {
    WorkRequest.cancelRequest(myWorkRequest.id)
      .then((res) => {
        if (res?.message !== 'Success'){
          finallyUpdate(`Something went wrong, try again later. ${res?.message ? res?.message : ""}`, false);
        } else {
          finallyUpdate("Work Request Updated with success", true);
        }
      })
      .catch((err) => {
        finallyUpdate("Something went wrong, try again later.", false);
      });
  };

  function HandleFileDownload(file) {
    downloadFile('workrequestfiles', file.fileName, file.filePath, openModalMessages)
  }

  function closePrintInformation() {
    setPrintInfoOpen(false);
  }

  function OpenPrintingOptions(sampleList, onlyWithBlindCode) {
    let arrayPrintInfo = [];

    const hasNewContainers = sampleList.some(s => s.newContainer?.containerNumber || s.newContainer?.containerNumber === 0);

    if (hasNewContainers) {
      sampleList.forEach((dataTable) => {  
        if (dataTable?.sample) {
          // const existingContainer = dataTable.existingContainer ?? dataTable?.sample?.containers.find(f=> f.id === dataTable.existingContainerID);
          // const containerNumbers = existingContainer?.containerNumber || existingContainer?.containerNumber === 0 ? [`${existingContainer?.containerNumber}`] :
          //   dataTable.newContainer?.containerNumber || dataTable.newContainer?.containerNumber === 0 ? [`${dataTable.newContainer?.containerNumber}`] : [];
          const containerNumbers = dataTable.newContainer?.containerNumber || dataTable.newContainer?.containerNumber === 0 ? [`${dataTable.newContainer?.containerNumber}`] : [];
        
          let newPrintInfo = new PrintLabel({
            sampleName: dataTable.sample.sampleName,
            containerNumbers: containerNumbers,
            includeAdditionalSampleInformation: false,
            chemIDOnly: false,
            printLabelSizeType: null,
            isBlindCoded: onlyWithBlindCode,
            blindDescription: null,
            blindSampleName: null,
            isShelfLabel: false,
            shelfLabelText: null,
            includeShelfBarcode: false
          });
    
          arrayPrintInfo.push(newPrintInfo);
        }
      });
  
      setPrintLabelInfo(arrayPrintInfo);
      setPrintInfoOpen(true);
    } else {
      openModalMessages('', 'A container needs to be created to be able to print!')
    }
  }

  return (
    <div>
      <div style={{ paddingBottom: "50px" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          //   ref={formRequestDetailsRef}

          >
            <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
              <Title>Request Details - {myWorkRequest?.id}</Title>
              <GlobalButton
                name="copy"
                variant="outlined"
                component={Link}
                to={{ pathname: '/newWorkRequests', state: { myWorkRequest: prepareData(myWorkRequest, true) } }}
              >
                Copy Work Request
              </GlobalButton>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <SubTitle>Work Request Common Info</SubTitle>
            {myWorkRequest && myWorkRequest.completedByEmail && (
              <div style={{ marginTop: "1rem", marginBottom: "0.5rem" }}>
                <p style={{ fontSize: "15px" }}>
                  Completed by: {myWorkRequest.completedByEmail}
                </p>
              </div>
            )}
            <Grid container spacing={2}>
              <Grid item xs={6} md={2}>
                <StyledAutocomplete
                  style={{ width: "100%" }}
                  disabled={true}
                  // disabled={!isTechnicianRole && !ownerCanEdit}
                  disablePortal
                  noOptionsText={
                    requestTypesLoading
                      ? "Loading Categories..."
                      : "No Categories Found"
                  }
                  options={requestTypes}
                  onChange={
                    (e, value) => {
                      AutoCompleteChangeAndValidate("typeName", value?.typeName)
                    }
                  }
                  getOptionLabel={(option) =>
                    option?.typeName
                      ? option.typeName
                      : requestTypes.length > 0
                        ? requestTypes.find((f) => f.typeName === option)
                          ?.typeName ?? option
                        : ``
                  }
                  autoHighlight
                  autoSelect
                  value={myWorkRequest.typeName}
                  isOptionEqualToValue={(option, value) =>
                    value?.typeName === option?.typeName
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      margin="normal"
                      label="Category"
                      error={
                        errorChecks.typeName === null
                          ? false
                          : errorChecks.typeName
                      }
                      helperText={
                        errorChecks.typeName ? errorChecks.typeName : ""
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={2}>
                <StyledAutocomplete
                  style={{ width: "100%" }}
                  disablePortal
                  disabled={
                    !isFacilityAssignorRole &&
                    !isTechnicianRole &&
                    !ownerCanEdit
                  }
                  noOptionsText={
                    requestFacilitiesLoading
                      ? "Loading Facilities..."
                      : "No Facilities Found"
                  }
                  options={requestFacilities}
                  getOptionLabel={(option) =>
                    option?.facilityName
                      ? option.facilityName
                      : requestFacilities.length > 0
                        ? requestFacilities.find((f) => f.facilityName === option)
                          ?.facilityName
                        : ``
                  }
                  onChange={(e, value) =>
                    AutoCompleteChangeAndValidate(
                      "facilityName",
                      value?.facilityName
                    )
                  }
                  autoHighlight
                  autoSelect
                  value={myWorkRequest.facilityName}
                  isOptionEqualToValue={(option, value) =>
                    value?.facilityName === option?.facilityName
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      margin="normal"
                      label="Facility"
                      error={
                        errorChecks.facilityName === null
                          ? false
                          : errorChecks.facilityName
                      }
                      helperText={
                        errorChecks.facilityName ? errorChecks.facilityName : ""
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
              </Grid>
              {isProcessWorkRequest && (
                <Grid item xs={3} md={3}>
                  <EmailAddressTextField
                    fontSize={16}
                    margin="normal"
                    fieldWidth="100%"
                    validatedUserEmail={
                      myWorkRequest.assignedToEmail === null
                        ? ""
                        : myWorkRequest.assignedToEmail
                    }
                    setValidatedUserEmail={(value) => {
                      setMyWorkRequest(() => ({
                        ...myWorkRequest,
                        assignedToEmail: value,
                      }));
                    }}
                    setHasErrors={(value) => {
                      setAssignToHasErrors(value);
                    }}
                    hasErrors={
                      assignToHasErrors && mustAssignPersonToWorkRequest
                    }
                    isDisabled={!isTechnicianRole && !isAssignerRole}
                    labelText="Assigned To"
                    placeholderText="Assigned To"
                    showPlusMeButton={false}
                  />
                </Grid>
              )}
              <Grid item xs={4} md={2}>
                <StyledAutocomplete
                  style={{ width: "100%" }}
                  // disabled={!isTechnicianRole && !ownerCanEdit}
                  disabled={isCancelledOrCompleted}
                  disablePortal
                  noOptionsText={
                    requestStatusLoading
                      ? "Loading Status..."
                      : "No Status Found"
                  }
                  options={isProcessWorkRequest ? requestStatus : ownerCanEdit ? requestStatus.filter(f => statusOwnerCanSee.includes(f.statusName)) : requestStatus}
                  getOptionLabel={(option) =>
                    option?.statusName
                      ? option.statusName
                      : requestStatus.length > 0
                        ? requestStatus.find((f) => f.statusName === option)
                          ?.statusName
                        : ``
                  }
                  onChange={(e, value) =>
                    AutoCompleteChangeAndValidate(
                      "statusName",
                      value?.statusName
                    )
                  }
                  autoHighlight
                  autoSelect
                  value={myWorkRequest.statusName}
                  isOptionEqualToValue={(option, value) =>
                    value?.statusName === option?.statusName
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      margin="normal"
                      label="Status"
                      error={
                        errorChecks.statusName === null
                          ? false
                          : errorChecks.statusName
                      }
                      helperText={
                        errorChecks.statusName ? errorChecks.statusName : ""
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} md={2}>
                <StyledAutocomplete
                  style={{ width: "100%" }}
                  disabled={isProcessWorkRequest ? !isRoleBusiness && !ownerCanEdit : !isTechnicianRole && !ownerCanEdit}
                  disablePortal
                  noOptionsText={
                    requestBusinessUnitsLoading
                      ? "Loading Business Units..."
                      : "No Business Units Found"
                  }
                  options={requestBusinessUnits}
                  onChange={(e, value) =>
                    AutoCompleteChangeAndValidate(
                      "businessUnitName",
                      value?.businessUnitName
                    )
                  }
                  getOptionLabel={(option) =>
                    option?.businessUnitName
                      ? option.businessUnitName
                      : requestBusinessUnits.length > 0
                        ? requestBusinessUnits.find(
                          (f) => f.businessUnitName === option
                        )?.businessUnitName
                        : ``
                  }
                  autoHighlight
                  autoSelect
                  value={myWorkRequest.businessUnitName}
                  isOptionEqualToValue={(option, value) =>
                    value?.businessUnitName === option?.businessUnitName
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      margin="normal"
                      label="* Business Unit"
                      error={
                        errorChecks.businessUnitName === null
                          ? false
                          : errorChecks.businessUnitName
                      }
                      helperText={
                        errorChecks.businessUnitName
                          ? errorChecks.businessUnitName
                          : ""
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4} md={4}>
                <StyledTextField
                  size="small"
                  margin="normal"
                  variant="outlined"
                  label="Requester Name"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 60 }}
                  onChange={(e) =>
                    setMyWorkRequest(() => ({
                      ...myWorkRequest,
                      requestedByEmail: e.target.value,
                    }))
                  }
                  value={
                    myWorkRequest.requestedByEmail === null
                      ? ""
                      : myWorkRequest.requestedByEmail
                  }
                  error={
                    errorChecks.requestedByEmail === null
                      ? false
                      : errorChecks.requestedByEmail
                  }
                  helperText={
                    errorChecks.requestedByEmail
                      ? errorChecks.requestedByEmail
                      : ""
                  }
                  disabled={true}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <BillingInfoField
                  billingValue={
                    myWorkRequest.chargeCode === null
                      ? ""
                      : myWorkRequest.chargeCode
                  }
                  handleChange={(e) => {
                    setMyWorkRequest(() => ({
                      ...myWorkRequest,
                      chargeCode: e,
                    }));
                  }}
                  hasErrors={
                    errorChecks.chargeCode === null
                      ? false
                      : errorChecks.chargeCode
                  }
                  isDisabled={!isTechnicianRole && !ownerCanEdit}
                  margin="normal"
                  fieldWidth="100%"
                  helperText={
                    errorChecks.chargeCode ? errorChecks.chargeCode : ""
                  }
                  marginLeft={null}
                  marginRight={null}
                  required={jadeRequired}
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <StyledTextField
                  disabled={true}
                  size="small"
                  margin="normal"
                  variant="outlined"
                  label="Created Date"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 60 }}
                  onChange={(e) => { }}
                  value={
                    myWorkRequest.createdDate === null
                      ? ""
                      : new Date(myWorkRequest.createdDate).toLocaleString()
                  }
                  error={
                    errorChecks.createdDate === null
                      ? false
                      : errorChecks.createdDate
                  }
                  helperText={
                    errorChecks.createdDate ? errorChecks.createdDate : ""
                  }
                />
              </Grid>
              <Grid item xs={2} md={2}>
                <StyledTextField
                  disabled={true}
                  size="small"
                  margin="normal"
                  variant="outlined"
                  label="Last Modified Date"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 60 }}
                  onChange={(e) => { }}
                  value={
                    myWorkRequest.lastModifiedDate === null
                      ? ""
                      : new Date(
                        myWorkRequest.lastModifiedDate
                      ).toLocaleString()
                  }
                  error={
                    errorChecks.lastModifiedDate === null
                      ? false
                      : errorChecks.lastModifiedDate
                  }
                  helperText={
                    errorChecks.lastModifiedDate
                      ? errorChecks.lastModifiedDate
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={10} md={8}>
                <StyledTextField
                  disabled={!isTechnicianRole && !ownerCanEdit}
                  size="small"
                  margin="normal"
                  variant="outlined"
                  label="Request Description"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 500 }}
                  multiline
                  rows={4}
                  value={myWorkRequest.description}
                  onChange={(e) =>
                    setMyWorkRequest(() => ({
                      ...myWorkRequest,
                      description: e.target.value,
                    }))
                  }
                />
              </Grid>
            </Grid>
            {formatCreatedAndModifiedDateInfo(myWorkRequest.createdDate, myWorkRequest.createdByEmail, myWorkRequest.lastModifiedDate, myWorkRequest.lastModifiedByEmail)}
            <SubTitle>Add Comment</SubTitle>
            <Grid container spacing={2}>
              <Grid item xs={8} md={8}>
                <StyledTextField
                  disabled={!isTechnicianRole && !isOwner}
                  size="small"
                  margin="normal"
                  variant="outlined"
                  label="Comment"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 100 }}
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={4}
                md={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "6px",
                }}
              >
                <GlobalButton
                  name="saveComment"
                  variant="contained"
                  onClick={() => addComment()}
                  disabled={
                    newComment === "" ||
                    !newComment ||
                    (!isTechnicianRole && !isOwner)
                  }
                >
                  Save Comment
                </GlobalButton>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={10} md={8}>
                <StyledTextField
                  size="small"
                  margin="normal"
                  variant="outlined"
                  label="Comments History"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 500 }}
                  multiline
                  rows={4}
                  value={
                    myWorkRequest.comments
                      ? mountComments(myWorkRequest.comments.split("|"))
                      : ""
                  }
                  disabled
                />
              </Grid>
            </Grid>

            <SubTitle>Sample Management Group Info</SubTitle>
            <Grid container spacing={2}>
              <Grid item xs={3} md={2}>
                <StyledAutocomplete
                  style={{ width: "100%" }}
                  disabled={!isTechnicianRole && !ownerCanEdit}
                  disablePortal
                  noOptionsText={
                    prioritiesLoading
                      ? "Loading Priorities..."
                      : "No Priorities Found"
                  }
                  options={priorities}
                  getOptionLabel={(option) =>
                    option?.priorityName
                      ? option.priorityName
                      : priorities.length > 0
                        ? priorities.find((f) => f.priorityName === option)
                          ?.priorityName
                        : ``
                  }
                  onChange={(e, value) =>
                    AutoCompleteChangeAndValidate(
                      "priorityName",
                      value?.priorityName
                    )
                  }
                  autoHighlight
                  autoSelect
                  value={myWorkRequest.priorityName}
                  isOptionEqualToValue={(option, value) =>
                    value?.priorityName === option?.priorityName
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      margin="normal"
                      label="SMG Priority"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ ...params.InputProps }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={5} md={3}>
                <StyledTextField
                  disabled={!isTechnicianRole && !ownerCanEdit}
                  size="small"
                  margin="normal"
                  variant="outlined"
                  label="SMG Priority Reason"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 100 }}
                  value={myWorkRequest.priorityReason}
                  onChange={(e) =>
                    setMyWorkRequest(() => ({
                      ...myWorkRequest,
                      priorityReason: e.target.value,
                    }))
                  }
                  error={
                    myWorkRequest.priorityName === "Rush" &&
                    !myWorkRequest.priorityReason
                  }
                />
              </Grid>
              <Grid item xs={3} md={1.5}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    disabled={!isTechnicianRole && !ownerCanEdit}
                    size="small"
                    inputFormat="MM/dd/yyyy"
                    label="Request Completion Date"
                    onChange={(e) =>
                      setMyWorkRequest(() => ({
                        ...myWorkRequest,
                        requestedCompletionDate: e,
                      }))
                    }
                    minDate={new Date()}
                    value={myWorkRequest.requestedCompletionDate}
                    renderInput={(params) => (
                      <StyledShortTextField
                        onKeyDown={DatePickerKeyDownEvent}
                        style={{ width: "100%" }}
                        {...params}
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ ...params.inputProps }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3} md={1.5}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    disabled={!isTechnicianRole && !ownerCanEdit}
                    size="small"
                    inputFormat="MM/dd/yyyy"
                    label="Last Acceptable Date"
                    onChange={(e) =>
                      setMyWorkRequest(() => ({
                        ...myWorkRequest,
                        lastAcceptableCompletionDate: e,
                      }))
                    }
                    minDate={new Date()}
                    value={myWorkRequest.lastAcceptableCompletionDate}
                    renderInput={(params) => (
                      <StyledShortTextField
                        onKeyDown={DatePickerKeyDownEvent}
                        style={{ width: "100%" }}
                        {...params}
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ ...params.inputProps }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3} md={1.5}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    disabled={!isTechnicianRole && !ownerCanEdit}
                    size="small"
                    inputFormat="MM/dd/yyyy"
                    label="Date Completed"
                    onChange={(e) =>
                      setMyWorkRequest(() => ({
                        ...myWorkRequest,
                        completedDate: e,
                      }))
                    }
                    minDate={new Date()}
                    value={myWorkRequest.completedDate}
                    renderInput={(params) => (
                      <StyledShortTextField
                        onKeyDown={DatePickerKeyDownEvent}
                        style={{ width: "100%" }}
                        {...params}
                        margin="normal"
                        variant="outlined"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ ...params.inputProps }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <SubTitle style={{ marginBottom: "16px" }}>
              Request Containers{" "}
              {categoriesToShowPrint.includes(myWorkRequest.typeName) && isProcessWorkRequest && <DescriptionLink
                onClick={() => {
                  OpenPrintingOptions(myWorkRequest?.workRequestSamples, false);
                }}
              >Print</DescriptionLink>}
            </SubTitle>
            <Grid container>
              <Grid item width={"100%"}>
                <WorkRequestSampleInfoTable
                  myWorkRequest={myWorkRequest}
                  setMyWorkRequest={setMyWorkRequest}
                  usingOnSiteTransport={usingOnSiteTransport}
                  unitOfMeasureLoading={unitOfMeasureLoading}
                  unitOfMeasure={unitOfMeasure}
                  locationsLoading={locationsLoading}
                  locations={locations}
                  ownerCanEdit={ownerCanEdit}
                  testJson={testJson}
                  setTestJson={setTestJson}
                  isProcessing={isProcessWorkRequest}
                  containerRecommendation={containerRecommendation}
                  setContainerRecommendation={setContainerRecommendation}
                  containerRecommendationErrorCheck={containerRecommendationErrorCheck}
                  setContainerRecommendationErrorCheck={setContainerRecommendationErrorCheck}
                  isTechnicianRole={isTechnicianRole}
                  isCancelledOrCompleted={isCancelledOrCompleted}
                />
              </Grid>
            </Grid>
            {usingShipping && nonChemicalShipping && (
              <WorkRequestNonChemicalTable
                nonChemicalShipping={nonChemicalShipping}
                unitOfMeasureLoading={unitOfMeasureLoading}
                unitOfMeasure={unitOfMeasureWUnit}
                updateData_ShippingItem={updateData_ShippingItem}
                ownerCanEdit={ownerCanEdit}
              />
            )}
            {!usingShipping &&
              !usingOnSiteTransport &&
              !usingSpecialAnalysis &&
              !usingContainerRegistration &&
              !usingContainerRegistrationTests && (
                <Box style={{ marginTop: "1rem" }}>
                  <MethodSelection
                    width="100%"
                    selectedMethods={testJson.methods}
                    setSelectedMethods={(value) =>
                      setTestJson((prev) => ({ ...prev, methods: value }))
                    }
                    showCompletionDate={false}
                    showContainerGrouping={false}
                    incomingTemplate={null}
                    showSearchTemplateName={true}
                    isReadOnly={!canChange}
                    showEstimateAndPriority={true}
                    maxMethodsAllowed={999}
                  />
                </Box>
              )}

            {showManageContainers && (
              <>
                <SubTitle style={{ marginBottom: "16px" }}>
                  Manage Containers
                </SubTitle>
                <ManageContainer
                  workRequest={myWorkRequest}
                  setWorkRequest={setMyWorkRequest}
                  testJson={testJson}
                  finallyUpdate={finallyUpdate}
                  setHasError={(value) => {
                    setContainersHasError(value);
                  }}
                  hasError={containersHasError}
                  prepareData={prepareData}
                  isTechnicianRole={isTechnicianRole}
                  currentUser={currentUser}
                  isProcess={isProcessWorkRequest}
                  isCancelledOrCompleted={isCancelledOrCompleted}
                />
              </>
            )}

            <SubTitle>File Attachments</SubTitle>
            <FileDragAndDrop
              // disabled={!isTechnicianRole && !ownerCanEdit}
              disabled={isCancelledOrCompleted}
              handleFiles={handleFiles}
              files={files.map((item) => item.file)}
              showFiles={false}
            ></FileDragAndDrop>
            <div
              style={{
                width: "50%",
                minWidth: "800px",
              }}
            >
              <UXDataTableWithoutBody
                tableWidth="100%"
                cols={FILE_ATTACHMENT_TABLE_COLUMNS}
                blankFirstHeader={true}
                tableId="midasFileAttachments"
                rowLength={files.length}
                enablePaging={false}
                rowsPerPage={files.length}
                page={0}
                handleChangePage={null}
                handleChangeRowsPerPage={null}
                noDataFoundMessage={"No Files Added"}
                enableAddIcon={false}
                addFunction={() => null}
                addToolTipText=""
                isDataLoading={false}
              >
                <StyledTableBody key={"myTablebody"}>
                  {files.map((file, rowIndex) => {
                    return (
                      <StyledTableRow hover key={`custom-row-${rowIndex}`}>
                        <StyledTableCell component="th" scope="row">
                          <IconButton
                            aria-label="upload"
                            component="span"
                            onClick={() => deleteFile(file)}
                            disabled={file.file.id || isCancelledOrCompleted ? true : false}
                          >
                            <TrashIcon />
                          </IconButton>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Button style={{ textTransform: "none" }} onClick={e => HandleFileDownload(file.file)}>{file.file.name}</Button>
                          {/* {file.file.filePath ? (
                            <a href={file.file.filePath}>{file.file.name}</a>
                          ) : (
                            <span>{file.file.name}</span>
                          )} */}
                        </StyledTableCell>
                        <StyledTableCell>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={isCancelledOrCompleted}
                                checked={
                                  file.file?.id
                                    ? file.file.sendWithShipment
                                    : file.sendWithShipment
                                }
                                onChange={(e) =>
                                  handleChangeFile("sendWithShipment", rowIndex)
                                }
                              ></Checkbox>
                            }
                            label={"Yes"}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={isCancelledOrCompleted}
                                checked={
                                  file.file?.id ? file.file.isSDS : file.isSDS
                                }
                                onChange={(e) =>
                                  handleChangeFile("isSDS", rowIndex)
                                }
                              ></Checkbox>
                            }
                            label={"Yes"}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </StyledTableBody>
              </UXDataTableWithoutBody>
            </div>
            {usingSpecialAnalysis && myWorkRequest && (
              <SpecialAnalysisForm
                myWorkRequest={myWorkRequest}
                specialAnalysisErrorCheck={specialAnalysisErrorCheck}
                formik={formik}
                readOnly={true}
                isTechnicianRole={isTechnicianRole}
                setMyWorkRequest={setMyWorkRequest}
                isCancelledOrCompleted={isCancelledOrCompleted}
              />
            )}
            <PrintInformation
              open={printInfoOpen}
              setOpen={setPrintInfoOpen}
              button2Action={closePrintInformation}
              button2Text={"Close"}
              printLabelInfo={printLabelInfo}
              isShelfLabel={false}
              useFieldsBlindCode={false}
            ></PrintInformation>
          </AccordionDetails>
        </Accordion>

        {usingShipping && (
          <ShippingForm
            myWorkRequest={myWorkRequest}
            shippingRequest={shippingRequest}
            expanded={expanded}
            handleChange={handleChange}
            setShippingRequest={setShippingRequest}
            shippingCarriersLoading={shippingCarriersLoading}
            shippingCarriers={shippingCarriers}
            isShippingRole={isShippingRole}
            ownerCanEdit={ownerCanEdit}
            updateData_ShippingItem={updateData_ShippingItem}
            // nonChemicalItems={nonChemicalItems}
            // setNonChemicalItems={setNonChemicalItems}
            dotHazardClass={dotHazardClass}
            dotHazardClassLoading={dotHazardClassLoading}
            isCancelledOrCompleted={isCancelledOrCompleted}
          />
        )}

        <ModalSimpleButton
          title={modalMessagesTitle}
          buttonText={modalMessagesButtonText}
          buttonAction={closeModalMessages}
          open={modalMessagesOpen}
          setOpen={setModalMessagesOpen}
        >
          <label style={{ whiteSpace: "break-spaces" }}>
            {modalMessagesText}
          </label>
        </ModalSimpleButton>
      </div>
      <div
        style={{
          position: "fixed",
          width: "8%",
          height: "100%",
          right: 0,
          top: 0,
          padding: "16px 8px",
          display: "flex",
          flexDirection: "column-reverse",
          gap: "8px",
        }}
      >
        {/* {isTechnicianRole ? ( */}
        <GlobalButton
          name="btnSave"
          variant="contained"
          onClick={() => submit()}
          disabled={isCancelledOrCompleted}
        >
          Save Changes
        </GlobalButton>
        {/* ) : <></>} */}

        {/* {isOwner && myWorkRequest.statusName === "Submitted" && (
          <GlobalButton
            name="btnPrint"
            variant="outlined"
            onClick={cancelRequestModal}
          >
            {"Cancel Request"}
          </GlobalButton>
        )} */}
        <GlobalButton name="btnPrint" variant="outlined" onClick={printRequest}>
          {instance.loading ? "Loading Print" : "Print Request"}
        </GlobalButton>
      </div>
      <ModalTwoButtons
        title={`Cancel Work Request #${myWorkRequest.id}`}
        button1Text={"Confirm"}
        button1Action={cancelRequest}
        button2Text={"Close"}
        button2Action={() => setModalCancelRequestIsOpen(false)}
        open={modalCancelRequestIsOpen}
      />
    </div>
  );
};

export default ViewWorkRequestForm;
